<template>
    <b-modal
      id="modal-select3"
      title="Ajouter une journée à l'itinéraire"
      ok-title="Enregistrer"
      cancel-variant="outline-secondary"
      cancel-title="Annuler"
      size="lg"
      @show="fillDataIfProvided"
      @ok='submitItineraryDay'
    >
      <b-form>
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <br>
          <h1 class="section-label mx-0 mt-0">
            Détails de la journée
          </h1>
          </b-col>
          <b-col md="6">
            <required-text-input-field
              v-model="currentDay.title"
              name="title"
              label="Titre"
              id="itinirary-title"

              label-for="itinirary-title"
              ref="itinirary-title"

              placeholder="Istanbul - Caire "
            />
          </b-col>
          <b-col md="6">
            <b-form-group label="Date" label-for="itinerary-date">
              <b-form-input
                id="itinerary-date"
                class="mb-1"
                type="date"
                v-model="currentDay.date"
                max="2100-01-01"
                name="itinerary-date"
                locale="fr"
                placeholder="Aucune date sélectionnée"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!--
          <b-col md="6">
            <b-form-group
              label="Kilométrage "
              label-for="mileage"
            >
              <b-form-input
                id="mileage"
                ref="mileage"
                type="number"
                v-model="currentDay.mileage"
              />
            </b-form-group>

          </b-col>
          -->
          <b-col md="6">
            <b-form-group
              label="Jour de voyage"
              label-for="description"
            >
              <b-form-input
                id="itinirary-day-index"
                ref="itinirary-day-index"
                type="number"

                v-model="currentDay.index"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Description"
              label-for="group-difficulty"
            >
              <b-form-textarea
                id="textarea-small"
                size="sm"
                v-model="currentDay.description"
                placeholder=""
                rows="3"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

                    <!-- custome checkbox and radio -->

        </b-row>
        <b-row>
          <b-col cols="12" class="mb-1">
            <h1 class="section-label mx-0 mt-2">Repas</h1>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Déjeuner"
              label-cols="5"
            >
              <b-form-checkbox
                v-model="currentDay.breakfast"
                class="mr-0 mt-50"
                name="is-rtl"


              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Dîner"
              label-cols="5"
            >
              <b-form-checkbox
                v-model="currentDay.dinner"
                class="mr-0 mt-50"
                name="is-rtl"

                inline
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Souper"
              label-cols="5"
            >
              <b-form-checkbox
                v-model="currentDay.supper"
                class="mr-0 mt-50"
                name="is-rtl"

                inline
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isGroupACruise">
          <b-col
            cols="12"
            class="mb-2"
          >
          <h1 class="section-label mx-0 mt-2">
            Information pour croisière
          </h1>
          </b-col>
          <b-col md="6">
            <b-form-group label="Heure d'arrivée" label-for="v-c-password">
              <div>
                <b-form-input
                  id="landing-date"
                  class="mb-1"
                  type="datetime-local"
                  max="2100-01-01T00:00"
                  v-model="landingDateTime"
                  locale="fr"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Heure de départ" label-for="v-c-password">
              <div>
                <b-form-input
                  id="boarding-date"
                  class="mb-1"
                  type="datetime-local"
                  max="2100-01-01T00:00"
                  v-model="boardingDateTime"
                  locale="fr"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Port" label-for="v-c-password">
              <b-form-input
                id="group-name"
                ref="group-name"
                v-model="currentDay.port_of_call"
              />
            </b-form-group>
          </b-col>
            <b-col md="6">
              <b-form-group
                label="Type de journée"
                label-for="cruise_day_type"
              >

                <v-select
                  id="cruise-day-type"
                  ref="cruise-day-type"
                  v-model="currentDay.cruise_day_type"
                  :options="itineraryConfig.DAY_TYPES"
                  :selectable="option => ! option.value.includes('select_value')"
                  label="text"
                  :reduce="text => text.value"           
                />

              </b-form-group>
            </b-col>

        </b-row>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h1 class="section-label mx-0 mt-2">Image</h1>
          </b-col>
          <b-col md="6">

            <b-form-file
              ref="file-upload"
              v-model="images.file1"
              placeholder=" Ajouter une image ici..."
              drop-placeholder="Déposer l'image ici..."
              @change="uploadImageToItineraryDay"
            ></b-form-file>
            <small class="form-text text-muted">Poids maximal de l'image: 5 Mo</small>

            <b-form-checkbox
              class="mr-0 mt-50"
              name="is-rtl"
              switch
              inline
            >
            ou choisir image par défault
            </b-form-checkbox>
          </b-col>
          <b-col md="6">
            <b-overlay
              id="overlay-background"
              :show="imageIsUploading"
              variant="transparent"
              opacity="0.85"
              blur="2px"
              rounded="sm"
              style="min-heigh:200px;"
            >
              <b-img :src="currentDay.image" fluid ></b-img>
            </b-overlay>
            <!-- <input type="file" accept="image/*" @input="uploadImage2($event)" id="file-input"> -->

          </b-col>
        </b-row>
      </b-form>
    </b-modal>
</template>

<script>
import dayjs from 'dayjs';
import BCardCode from '@core/components/b-card-code'
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BRow,BCol, BCard,BInputGroup, BOverlay,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormTextarea,
  BTabs,
  BFormFile,
  BTab,
  BImg,
  BFormInvalidFeedback,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {useToastNotification} from "@/shared/composables/use-toast-notifications";

import {ref, reactive} from "@vue/composition-api/dist/vue-composition-api";
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import Vue from 'vue'
import RequiredTextInputField from "@/shared/components/RequiredTextInputField";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from '@validations';
import itineraryConfig from '@core/data/itineraryConfig.js'

export default {
  components: {
    BOverlay,
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BImg,
    BTab,
    BFormInvalidFeedback,
    RequiredTextInputField,
    
    },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: ['itineraryDay', 'groupType'],
  data() {
    return {}
  },
  setup(props, {emit}) {
    const {uploadImage} = useGroupApi();
    const {displayErrorMessage} = useToastNotification();

    const initialState = {
      boarding: null,
      breakfast: null,
      date: null,
      description: null,
      dinner:null,
      image:null,
      landing:null,
      mileage:null,
      port_of_call:null,
      supper:null,
      title:null,
      index:null,
      cruise_day_type: null
    }

    let images = reactive({})
    let currentDay = reactive({ ...initialState})
    let imageIsUploading = ref(false)

    const submitItineraryDay = () => {
      emit('submitItineraryDay', currentDay)
    }

    function resetItineraryForm() {
      Object.assign(currentDay, initialState);
    }

    const fillDataIfProvided = () => {
      resetItineraryForm();
    }

    const uploadImageToItineraryDay = async (event) => {
      try{
        imageIsUploading.value = true;
        let file = event.target.files[0];

        if (file) {
          let data = new FormData();
          data.append('name', file.name);
          data.append('type', 'image');
          data.append('file', event.target.files[0]);

          let response = await uploadImage(data)
          Vue.set(currentDay, 'image', response.headers.location)    
        }
      } catch (error) {
        displayErrorMessage("Le téléversement de l'image a échoué.");
        images.file1 = null
        this.$refs['file-upload'].reset()
      } finally {
        imageIsUploading.value = false;
      }
    }

    return {
      submitItineraryDay,
      images,
      uploadImageToItineraryDay,
      currentDay,
      imageIsUploading,
      fillDataIfProvided,
      itineraryConfig

    }
  },
  computed: {
    getImage() {
      return this.itineraryDay.image
    },
    boardingDateTime: {
       get() {
        const value = dayjs(this.currentDay.boarding, 'YYYY-MM-DD HH:mm');
        return value.format('YYYY-MM-DDTHH:mm');
      },
      set(value) {
        const newDate = dayjs(value, 'YYYY-MM-DDTHH:mm');
        this.currentDay.boarding = newDate.format('YYYY-MM-DD HH:mm');
      }
    },
    landingDateTime: {
       get() {
        const value = dayjs(this.currentDay.landing, 'YYYY-MM-DD HH:mm');
        return value.format('YYYY-MM-DDTHH:mm');
      },
      set(value) {
        const newDate = dayjs(value, 'YYYY-MM-DDTHH:mm');
        this.currentDay.landing = newDate.format('YYYY-MM-DD HH:mm');
      }
    },
    isGroupACruise() {
      if(this.groupType == 'CRUISE') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
